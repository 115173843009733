<style scoped>

.home-title {
    font-size: 28px;
    font-weight: 500;
}

img {
    max-width: 300px;
    margin-top: 60px;
	border-radius: 1rem;
}

@media only screen and (max-width: 580px) {
    img {
        object-fit: cover;
        border-radius: 50%;
        height: 200px;
        width: 200px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 2px solid rgb(205, 205, 205);
    }
}

.fa {
    font-size: 15px;
}

.btn {
    border-color: #669db3ff;
    color: #669db3ff;
}

.btn:hover {
    background-color: #669db3ff;
    border-color: #669db3ff;
    color: white;
}

.btn:focus {
    background-color: #669db3ff;
    border-color: #669db3ff;
    color: white;
}

.btn:focus {
    outline: none !important;
}

p {
    text-align: justify;
    font-weight: 400;
}

</style>

<template>

<div :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }" class="pt-5 p-st">
    <div class="container" data-aos="fade" data-aos-once="true" data-aos-duration="1000">
        <div class="row align-items-center">
            <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
                <img :src="picture" />
            </div>
            <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-5">
                <span class="home-title" :class="{ pgray: !nightMode, 'text-light': nightMode }">hello there!</span
          >
          <div>
            <p v-html="description"></p>
          </div>
          <div class="text-center pb-4">
            <button
              class="btn btn-outline-secondary mx-2 "
              @click="open('linkedin')"
              v-tooltip.bottom="'LinkedIn'"
            >
              <i class="fab fa-linkedin"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('github')"
              v-tooltip.bottom="'GitHub'"
            >
              <i class="fab fa-github"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('angellist')"
              v-tooltip.bottom="'AngelList'"
            >
              <i class="fab fa-angellist"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('resume')"
              v-tooltip.bottom="'Resume'"
            >
              <i class="fa fa-file"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import info from "../info";

export default {
    name: "Home",
    props: {
        nightMode: {
            type: Boolean,
        },
    },
    data() {
        return {
            picture: info.flat_picture,
            description: info.description,
            name: info.name,
            linkedin: info.links.linkedin,
            github: info.links.github,
            angellist: info.links.angellist,
            resume: info.links.resume
        };
    },
    methods: {
        open(link) {
            switch (link) {
                case "linkedin":
                    window.open(this.linkedin, "_blank");
                    break;
                case "github":
                    window.open(this.github, "_blank");
                    break;
                case "angellist":
                    window.open(this.angellist, "_blank");
                    break;
                case "resume":
                    window.open(this.resume, "_blank");
                    break;
            }
        },
    },
};

</script>
