<style>

#app {
    font-family: Poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
}

.parent {
    position: relative;
}

.pgray {
    color: #535a5e;
}

.pblue {
    color: #669db3ff;
}

.bg-dark2 {
    background-color: #262c30 !important;
}

.text-light {
    color: #d3d2d2 !important;
}

.p-st {
    transition: all 0.5s !important;
}

.tooltip {
    display: block !important;
    z-index: 10000;
}

.tooltip .tooltip-inner {
    background: rgb(212, 149, 97);
    color: white;
    border-radius: 8px;
    font-size: 10px;
    /* padding: 5px 10px 4px; */
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: rgb(212, 149, 97);
    z-index: 1;
}

.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
    margin-top: 10px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
    margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip[x-placement^="left"] {
    margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
}

.tooltip[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s;
}

.giscus {
    padding: 1rem;
}

</style>

<template>

<div id="app">
    <Navbar @scroll="scrollTo" />
    <div class="parent">
        <Home />
        <About id="about" />
        <Skills id="skills" />
        <div class="giscus">
            <Giscus repo="rivercory/portfolio" repoId="R_kgDOMdAnWQ" categoryId="DIC_kwDOMdAnWc4ChSn2" mapping="pathname" reactionsEnabled="1" emitMetadata="1" inputPosition="too" theme="preferred_color_scheme" lang="ko">
            </Giscus>
        </div>
        <Footer />
    </div>
</div>

</template>

<script>

import Giscus from '@giscus/vue';
import Navbar from "../components/Navbar.vue";
import Home from "../components/Home";
import About from "../components/About";
import Skills from "../components/Skills";
import Footer from "../components/Footer";
import info from "../info";

export default {
    name: "App",
    components: {
        Navbar,
        Home,
        About,
        Skills,
        Footer,
        Giscus
    },
    data() {
        return {
            nightMode: false,
            config: info.config,
        };
    },
    mounted() {
        ["about", "skills"].forEach((l) => {
            if (window.location.href.includes(l)) {
                var elementPosition = document.getElementById(l).offsetTop;
                window.scrollTo({
                    top: elementPosition - 35,
                    behavior: "smooth"
                });
            }
        });
    },
    methods: {
        scrollTo(ele) {
            if (ele == "home") {
                this.$router.push(`/`);
                window.scrollTo({
                    top: -80,
                    behavior: "smooth"
                });
            } else {
                var elementPosition = document.getElementById(ele).offsetTop;
                window.scrollTo({
                    top: elementPosition - 35,
                    behavior: "smooth"
                });
                if (this.$router.history.current.path !== `/${ele}`)
                    this.$router.push(`/${ele}`);
            }
        },
    },
};

</script>
