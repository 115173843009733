<style scoped>

.nav-link {
    font-weight: 500;
}

nav {
    position: fixed !important;
}

.navbar-blur {
    background-color: #ffffff7e;
    backdrop-filter: blur(12px);
}

</style>

<template>

<div>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top p-st" :class="{
        'navbar-blur': navbarConfig.blur,
      }">
        <a class="navbar-brand" href="/" @click.prevent="$emit('scroll', 'home')">
            <Logo />
        </a>
    </nav>
</div>

</template>

<script>

import Logo from "./helpers/Logo";
import info from "../info";

export default {
    name: "Navbar",
    props: {
        nightMode: {
            type: Boolean,
        },
    },
    data() {
        return {
            navbarConfig: info.config.navbar,
        };
    },
    components: {
        Logo,
    },
    methods: {},
};

</script>
