let info = {
  name: "Hyeonho Kang",
  logo_name: "rivercory",
  flat_picture: "https://avatars.githubusercontent.com/u/116472903?v=4",
  config: {
    navbar: {
      blur: true
    }
  },
  description:
    "Hi! I'm Hyeonho Kang. <br> I'm middle school student and study in Incheon Cheongram Middle School.",
  links: {
    linkedin: "https://www.linkedin.com/in/hrishikeshpaul/",
    github: "https://github.com/hrishikeshpaul",
  },
  education: [
    {
      name: "Incheon Wondong Elementary School",
      date: "March, 2017 - March, 2022",
      degree: "Masters in Computer Science",
      gpa: "3.7/4.0",
      description:
        "Currently pursuing my Master's in Computer Science, while specializing in the field of Artificial Intelligence and Machine Learning.",
      skills: [
        "Artificial Intelligence",
        "Software Engineering",
        "Algorithms",
        "Computer Vision",
        "Music Data Mining",
        "OS"
      ]
    }
  ],
  experience: [
    {
      name: "Cyberinfrastructure for Network Science Center",
      place: "Bloomington, Indiana",
      date: "Jun, 2020 - Present",
      position: "Research Assistant; Full Stack Developer",
      description:
        "Worked as a Software Developer for the Human BioMolecular Atlas Program (funded by the NIH), building innovative visualization tools to envision large biomedical datasets.",
      skills: ["Angular", "Node.js", "Vega", "Shell", "Typescript"]
    }
  ],
  skills: [
    {
      title: "languages",
      info: [
        "Python",
        "Javascript",
        "TypeScript",
        "Java",
        "C",
        "C++",
        "SQL",
        "NoSQL",
        "Ruby"
      ],
      icon: "fa fa-code"
    },
    {
      title: "web technologies",
      info: ["Vue", "Angular", "React", "Node", "Flask", "HTML", "CSS"],
      icon: "fas fa-laptop-code"
    },
    {
      title: "design",
      info: ["Illustrator", "XD", "Photoshop", "Final Cut Pro"],
      icon: "fa fa-pencil-square-o"
    }
  ]
};

export default info;
